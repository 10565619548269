import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";

const DarkSection = styled.div`
  background: #06374d;
  padding: 4rem 2rem;
  @media (min-width: 900px) {
    padding: 3rem 6rem;
  }
  @media (min-width: 1500px) {
    padding: 3rem 12rem;
  }
`;

const LightSection = styled.div`
  background: #115876;
  padding: 4rem 2rem;
  @media (min-width: 900px) {
    padding: 3rem 6rem;
  }
  @media (min-width: 1500px) {
    padding: 3rem 12rem;
  }
`;

export default function UskomuksetPage(params) {
  return (
    <Layout>
      <title>Pyhät tekstit</title>
      <DarkSection>
        <h1>Pyhät tekstit</h1>
        <p>
          Norpattu on muinaisista pyhistä teksteistä käännetty pyhä kirja.
          Käännöstyö on vielä kesken ja norpattua päivitetään käännösten
          valmistuessa.
        </p>
      </DarkSection>
      <LightSection>
        <h1>NORPATTU</h1>

        <h2>Aikojen alku</h2>
        <p>
          Alussa oli Saimaa, kuutti ja juhannus. Jupiterin ollessa 24 asteen
          kulmassa, kaikkivoipa mahtava Cthulhu päätti yksinolon haureuden
          olevan liikaa ja halusi luoda elämää kolkolle vesiplaneetalle, jota
          Tellukseksi kutsutaan. Cthulhu keräsi kaikki voimansa ja luovutti
          viisi lonkeroansa, joista hän muovasi kaksi räpylää, yhden pyrstön ja
          muhkean keskivartalon. Sen jälkeen hän muovasi masuun kerääntyneestä
          rasvasta pään ja tähän kovan kitiini-kalkki -sarven. Hän yhdisteli
          palaset keskenään ja loitsi vielä siipiensä sulista olennolle
          tietoisuuden sekä ruuansulatuksen. Näin sai alkuunsa Pällervö,
          arkkijumala ja Norppien äiti.
        </p>
        <p>
          {" "}
          Pällervö kuitenkin vainui nopeasti seitinohueeseen depressioon
          lipuessa vesistöjä yksinäisyydessään, joten Cthulhu ymmärsi luoda
          Pällervölle kumppanin tämän yhdestä ainoasta puolustuskeinosta,
          otsassa kasvavasta sarvesta. Näin sai aikaansa Pullervo, Pällervön
          tuleva puoliso sekä miesoletettujen ensimmäinen vedos. Tästä syystä
          norpilla ei enää ole sarvea, eikä näin taistelukeinoa oman
          vartalomassan lisäksi. Tämä ei norppia tosin haittaa, sillä norpat
          omaavat kyvyn hallita maailmaa jumalallisilla voimillansa. Pullervo ja
          Pällervö yhdessä useiden ylä- ja alamäkien säestämänä loivat neljä
          taivaallista kuuttia; Hällervön, Nällervön, Nollervon sekä Gullervon.
          Neljän lapsen vanhempina Pullervo ja Pällervö totesivat yleisen
          kotirauhan säilyttääkseen ja parisuhteen pelastamisekseen kaipaavansa
          hieman kodinhoitoapua. Yhdessä Cthulhun kanssa norpat suunnittelivat
          Pyhät lahnat, joista valioyksiköistä valikoitiin lahna-armeija, jotka
          toimivat kyseenalaistamatta uskollisesti norppien palveluksen
          alaisena.{" "}
        </p>
        <p>
          Kuuttien kasvaessa norpat huomasivat olevansa tylsistyneitä tyhjässä
          vedentäytteisessä Telluksessa. He kuluttivat energiaansa keskenään
          kinasteluun ja toistensa kiusaamiseen. Tämä johti suureen Norppa
          Warsiin, jonka seuraukset olivat traagiset. Muutaman vakavamman
          vahingon jälkeen Cthulhu päätti, että jatkuvalle kinastelulle on
          saatava loppu. Sodat tulivat loppuunsa, ja kaikesta harmista ja
          tragediasta syntyi tuhon aiheuttama mikrobiaalto, josta kehittyi
          orgaanisia kombinaatioita. Tätä me kuolevaiset kutsumme evoluutioksi,
          jonka avulla saatiin rikas ja monimuotoinen planeetta, jossa norpat
          voivat vapaasti temmeltää ja aiheuttaa hämmennystä satuttamatta
          toisiaan (liikaa). Näin sai aikaansa maailma, ja kaikki sen
          ainutlaatuiset maagiset elämänmuodot.
        </p>
        <h2>Ensimmäinen Pällervön kirja</h2>
        <p>
          Pällervö ei muista luomisen alusta paljoakaan, mutta hän muistaa
          Cthulhun lempeän, isällisen sylin. Cthulhu hoivasi ja hoiti Pällervöä
          vahvalla, mutta kiltillä otteella ja viihdytti tätä parhaansa mukaan.
          Pällervö on aikojen alusta ollut itsenäinen ja sinnikäs,
          omanarvontuntoinen vahva norppatar. Pällervö kuitenkin kärsi
          yksinäisyydestä ja kaipasi lajitoveria. Vaikka hän oli rautaakin
          lujempi, niin ihan kuin raudallakin, Pällervön sydämelläkin on
          sulamispiste ja Pällervö oli kovasti olomuodon muutoksen tarpeessa.
          Vahvinkin johtaja tarvitsee jotain, ketä johdattaa.
        </p>
        <p>
          Cthulhu näki Pällervön kärsimyksen, mutta ei ollut vielä toipunut
          täysin Pällervön luomisesta ja näin ei kyennyt luovuttamaan
          lonkeroitansa uuteen elävään olentoon. Hän kuitenkin keksi, että
          Pällervön sarvesta saisi ripauksella taikaa loihdittua heimolaisen.
          Pienellä määrällä magiaa ja uhrausta Cthulhu voisi varmistaa, ettei
          maan päällä ole yksinäistä enää. Näin hän pyysi Pällervöltä
          suostumusta kertoen hänelle myös seurauksista. Taatakseen
          lajitoveruuden Pällervön tulisi luopua jostain hänelle tärkeästä ja
          turvaa luovasta ominaisuudesta, Pällervön sarvesta. Cthulhun sydäntä
          riipaisi ajatus hänen rakkaasta rasvapallerostaan ilman
          puolustuskeinoja täysin haavoittuvaisena ekstraterrestiaalisille
          hyökkäyksille. Hän oli kuitenkin valmis tekemään lähes mitä tahansa
          varmistaakseen jälkeläisensä onnen. Pällervön ei tarvinnut kahta
          kertaa asiaa pohtia, hän tiesi olevansa valmis luopumaan vaikka
          vasemmasta räpylästään kumppanuuden tunteen takia. Näin Cthulhu
          aloitti rituaalin keräämällä haaviinsa tarvittavat ainesosat:
          <ul>
            <li>keijupölyä</li>
            <li>suolaisia kyyneleitä</li>
            <li>puhdasta etanolia</li>
            <li>kourallinen epätoivoa </li>
          </ul>
          Cthulhu keräsi ainekset savipataan ja lausui jumalallisella kielellä
          kuolleita loitsuja. Ja BOOM! Padasta ryömi, hitaasti mutta varmasti,
          ylös hyvällä rasvapeitteellä varustettu suojaiän ylittänyt urosnorppa.
          Norppa, joka nimitettiin Pullervoksi.
        </p>
        <p>
          Pällervö ei voinut uskoa silmiänsä. Voi mikä uljaus. Voi mikä miehisen
          raukea laupeus. Voi mikä masu. Edes Pullervon epätyylikkäästi
          asettuneet rinkulat eivät riittäneet kääntämään Pällervön himokasta
          katsetta tästä luomistyön tuotoksesta. Ei tarvittu vuosituhansia
          aikaa, että Pällervölle selvisi, että valitettavasti älykkyys ei
          selviytynyt metamorfoosissa ja Pullervo ei sattumoisin ollut mikään
          Saimaan rannan sähäköin särki. Tämä oli kuitenkin pieni vika ja vielä
          pienempi hinta siitä, että saisi vihdoinkin kokea aidon yhteyden
          toiseen toveriin. Tämä oli kauniin tarinan alku ja pyhän jumalperheen
          syntytarina.{" "}
        </p>
        <h2>Book of All Hallows' Eve</h2>
        <p>
          Things are good in the Norppa world, the guidance of John, Jane Doe’s
          once murderous ex-husband being a prime example. However, a threat
          looms across the horizon. A cthonic titan, the great Cookie Monster
          himself, could be a threat to our very godhood. And Cookie Monster is
          attending a Halloween party with John! That is both very meta and
          convenient. We shall hold communion with Cookie Monster in a place he
          feels comfortable, the pantry. Does he seek balance, or rapture?
        </p>
      </LightSection>
    </Layout>
  );
}
